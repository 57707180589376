<template>
  <div class="localisation-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Localisation List</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <LocalisationFilterForm
              ref="filterForm"
              :values="filters"
              slot="filter"
            />
          </DataFilter>

          <br />

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
            }"
          >
            <template slot="item.site" slot-scope="{ item }">
              {{item.site.nom}}
            </template>
            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import LocalisationFilterForm from '../../components/localisation/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';

export default {
  name: 'LocalisationList',
  servicePrefix: 'Localisation',
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    LocalisationFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        { text: 'nom', value: 'nom' },
        { text: 'site', value: 'site' },
        { text: 'salle', value: 'salle' },
        { text: 'etage', value: 'etage' },
        { text: 'batiment', value: 'batiment' },
        { text: 'visible', value: 'visible' },
        {
          text: 'Actions',
          value: 'action',
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters('localisation', {
      items: 'list'
    }),
    ...mapFields('localisation', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('localisation', {
      getPage: 'fetchAll',
      deleteItem: 'del'
    })
  }
};
</script>
